<template>
    <page>
        <page-head>
            
            <section class="text-gray-600 body-font">
                <div class="container px-5 mx-auto ">
                    
                    <div class="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-6 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
                        <div class="relative flex-grow w-full">
                            <label for="from-date" class="leading-7 text-sm text-gray-600">Başlanğıc tarixi:</label>
                            <input type="date" id="from-date" v-model="form.fromDate" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-6 transition-colors duration-200 ease-in-out">
                        </div>

                        <span class="mx-4 text-gray-500">to</span>

                        <div class="relative flex-grow w-full">
                            <label for="to-date" class="leading-7 text-sm text-gray-600">Bitiş tarixi:</label>
                            <input type="date" id="to-date" v-model="form.toDate" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-6 transition-colors duration-200 ease-in-out">
                        </div>

                        <!-- <button class="text-white bg-indigo-500 border-0 py-1 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Göndər</button> -->
                    </div>

                </div>
            </section>

        </page-head>

        <page-body>
            
            <section class="text-gray-600 body-font">
                <div class="container px-5 py-10 mx-auto">
                    
                    <div class="flex flex-wrap -m-4">

                        <ExcelCard title="Aylıq Cədvəl" text="Bütün filialların ay ərzində gəlirləri.">
                            
                            <!-- <a style="padding: 5px 5px; border: 1px solid black;" 
                               href="127.0.0.1:8000/custom_download" >download</a> -->
                                <!-- look here, this is not working -->
                            
                            <app-button style="margin-top:10px;" @click="getAllMonthly" icon="icon-download" property="success">
                                {{ 'yüklə' }}
                            </app-button>
                        </ExcelCard>

                        <ExcelCard title="Detallı Cədvəl" text="Bütün filialların detallı satışları qeyd olunub.">
                            
                            <!-- <a style="padding: 5px 5px; border: 1px solid black;" 
                               href="127.0.0.1:8000/custom_download" >download</a> -->
                                <!-- look here, this is not working -->
                            
                            <app-button @click="getDetailedRepo" icon="icon-download" style="margin-top:10px;" property="success">
                                {{ 'yüklə' }}
                            </app-button>
                        </ExcelCard>

                        <ExcelCard title="Portal Shop Revizya" text="Bütün filialların revizyası.">
                            
                            <!-- <a style="padding: 5px 5px; border: 1px solid black;" 
                            href="127.0.0.1:8000/custom_download" >download</a> -->
                                <!-- look here, this is not working -->
                            
                            <app-button @click="getPortalShopRepo" icon="icon-download" style="margin-top:10px;" property="success">
                                {{ 'yüklə' }}
                            </app-button>
                        </ExcelCard>

                        
                        <ExcelCard title="Xərclər" text="Bütün filialların xərcləri.">
                            
                            <!-- <a style="padding: 5px 5px; border: 1px solid black;" 
                            href="127.0.0.1:8000/custom_download" >download</a> -->
                                <!-- look here, this is not working -->
                            
                            <app-button @click="getCostsRepo" icon="icon-download" style="margin-top:10px;" property="success">
                                {{ 'yüklə' }}
                            </app-button>
                        </ExcelCard>
                        
                        <ExcelCard title="Yekun" text="Yekun hesabat.">
                            <app-button @click="getTotalIncomeRepo" icon="icon-download" style="margin-top:10px;" property="success">
                                {{ 'yüklə' }}
                            </app-button>
                        </ExcelCard>
                    </div>
                </div>
            </section>

        </page-body>

        
    </page>
</template>

<script>
/*
 * Import Components
 * */
import { exit } from 'process';
import {mapActions, mapState} from 'vuex';
import ExcelCard from '../../common/components/card/ExcelCard.vue';
import FileSaver from 'file-saver';
import axios from "axios";

const modalId = 'createModal';
const translateKey = 'crm.Vacancies';

export default {
    name: "AccountingIndex",
    data() {
        return {
            submit_btn: 0,
            translateKey,
            modalId,
            modelShow: false,
            photoItems: [],
            form: {}
        };
    },
    computed: {
        // ...mapState("VacanciesStore", ["vacancies"]),
        permission() {
            return this.currentPage.permission;
        }
    },
    methods: {
        ...mapActions("AccountingStore", ["getAllRepoMonthly", "getAllRepoMonthlySeconWay"]),
    
        getAllMonthly(){
            // window.open('http://127.0.0.1:8000/api/crud/accounting/get-excel-report', '_blank');
            // window.location = 'http://127.0.0.1:8000/excel/download/all-monthly';
            // I should use https://portalgames.az/ ...
            // console.log(this.form);
            // exit;
            if(this.form.fromDate && this.form.toDate){
                window.open("https://portalgames.az/excel/download/all-monthly/"+this.form.fromDate+"/"+this.form.toDate).focus();
            }else{                
                window.open("https://portalgames.az/excel/download/all-monthly").focus();
            }
        },

        getDetailedRepo(){
            if(this.form.fromDate && this.form.toDate){
                window.open("https://portalgames.az/excel/download/detailed-repo/"+this.form.fromDate+"/"+this.form.toDate).focus();
            }else{                
                window.open("https://portalgames.az/excel/download/detailed-repo").focus();
            }
        },
        
        getPortalShopRepo(){
            if(this.form.fromDate && this.form.toDate){
                window.open("https://portalgames.az/excel/download/portal-shop/"+this.form.fromDate+"/"+this.form.toDate).focus();
            }else{                
                window.open("https://portalgames.az/excel/download/portal-shop").focus();
            }
        },

        getCostsRepo(){
            if(this.form.fromDate && this.form.toDate){
                window.open("https://portalgames.az/excel/download/costs/"+this.form.fromDate+"/"+this.form.toDate).focus();
            }else{                
                window.open("https://portalgames.az/excel/download/costs").focus();
            }
        },

        getTotalIncomeRepo(){
            if(this.form.fromDate && this.form.toDate){
                window.open("https://portalgames.az/excel/download/total-income/"+this.form.fromDate+"/"+this.form.toDate).focus();
            }else{                
                window.open("https://portalgames.az/excel/download/total-income").focus();
            }
        },


        getAllMonthlySeconWay(){
            return this.getAllRepoMonthlySeconWay();
        }
    },
    
    created() {
    },
    components: { ExcelCard }
}
</script>

<style scoped>

</style>
